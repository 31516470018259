





















import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
import { ClassColumnDataTable } from "@/components/DataTableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import BaseListaClass from "@/shared/classes/BaseListaClass";
import { RouterNames } from "@/router/routernames";
import { ClassTypeSelectionRow } from "@/components/DataTableGenerico/ClassTypeSelectionRow";
import { MessageService } from "@/shared/services/message-service";
import pantalla_modo_listaModule from "@/store/modules/pantalla_modo_lista-module";
import { pantalla_modo_lista } from "@/shared/dtos/dynamic_tables/pantalla_modo_lista";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Component({
  components: {},
})
export default class DxFullDataTable extends Mixins(BaseListaClass) {
  public FormKey: number = 0;
  public FormId: number = -1;
  public pantalla_modo_lista: pantalla_modo_lista = new pantalla_modo_lista();
  public datasource: any[] = [];
  @Prop({ default: 0 }) id_tabla!: number;
  public get DataSource() {
    try {
      ssmHttpService
        .get(API.webApiBase + this.pantalla_modo_lista.pantalla.api_call)
        .then((x) => {
          this.datasource = x;
        });
    } catch (error) {}
    this.datasource = [];

    return "";
  }

  public RowRemoved(e: any) {}

  public Salir() {
    //@ts-ignore
    this.DataTable.VisualDxDataGrid.instance.cancelEditData();
  }

  public EmpiezoEditar(e: any) {
    this.FormId = parseInt(this.DataTable.RowIndex());
    this.FormKey += 1;
    // let index = this.DataTable.RowIndex();
    // this.$router.push({
    //   name: RouterNames.paisesformulario,
    //   query: { Id: index }
    // });
  }
  public EmpiezoCrear(e: any) {
    this.FormId = -1;
    this.FormKey += 1;
    // this.$router.push({
    //   name: RouterNames.paisesformulario,
    //   query: { Id: "-1" }
    // });
  }

  public get Columns() {
    let Columnas: ClassColumnDataTable[] = [];
    if (this.pantalla_modo_lista.columnas === undefined) {
      return Columnas;
    }

    for (let i = 0; i < this.pantalla_modo_lista.columnas.length; i++) {
      let item = this.pantalla_modo_lista.columnas[i];
      var datatype = datatypes.string;

      switch (item.tipo_de_dato.nombre) {
        case "string":
          var datatype = datatypes.string;
          break;
        case "boolean":
          var datatype = datatypes.boolean;
          break;
        case "numeric":
          var datatype = datatypes.number;
          break;
        case "Date":
          var datatype = datatypes.date;
          break;
        case "DateTime":
          var datatype = datatypes.datetime;
          break;
        default:
          var datatype = datatypes.string;
          break;
      }

      Columnas.push(
        new ClassColumnDataTable(
          item.data_bilding,
          item.nombre,
          datatype,
          item.visible,
          datatypes.maxlength
        )
      );
    }

    return Columnas;
  }
  get get_id_tabla() {
    let id = this.$route.meta.id;
    if (id === undefined) {
      id = this.$route.params.id;
    }
    if (this.id_tabla > 0) {
      id = this.id_tabla;
    }
    return id;
  }
  public async Refresh() {
    pantalla_modo_listaModule
      .getpantalla_modo_lista(this.get_id_tabla)
      .then((x: pantalla_modo_lista) => {
        this.pantalla_modo_lista = x;
      });
  }
}
